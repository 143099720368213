.Desktop {
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}

.background {
    background-image: url('../../IMG/wepik-monocolor-nature-landscape-desktop-wallpaper-20230823200517H0pM.png');
    display: flex;
    background-size: cover;
    height: 95vh;
    width: 100vw;
}


/* Taskbar ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

.clock {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    justify-self: flex-end;
    padding-right: 15px;
}

.toolTip {
    min-width: 49px;
    border: 1px solid black;
    background-color: white;
    text-align: center;
    padding: 5px;
    position: absolute;
    z-index: 9999999999999999999;
    bottom: 5%;
}

.taskBar {
    width: 100vw;
    height: 5vh;
    background-color: rgba(5, 33, 56, 0.849);
    display: flex;
    justify-content: space-between;
}

.taskBarItems {
    width: 80vw;
    height: 5vh;
    display: flex;
}

.taskBarBox {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 49px;
}

.taskBarBox svg {
    color: white; 
    width: 50%;
    height: 100%;
}

.taskBarBox:hover {
    background-color: rgba(66, 121, 165, 0.514);
    cursor: default;
}

.activeBar {
    top: 2%;
    width: 90%;
    left: 2%;
    height: 2%;
    background-color: gray;
}

/* Start --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

.Start {
    width: 25vw;
    overflow: hidden;
    height: 60vh;
    position: absolute;
    bottom: 5.5vh;
    left: .5vw;
    border: 1px solid black;
    background-color: rgb(37, 64, 87);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    transition: all .2s;
    z-index: 99999;
}

.startClosed {
    height: 0!important;
    border: 0!important;
    transition: all .2s;
}

.search {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.appsList {
    width: 100%;
    height: 40%;
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    grid-template-rows: 30% 30% 30%; 
    grid-template-areas: 
    "header header header header";
    column-gap: 1%;
    row-gap: 5%;
    justify-content: center;
    align-items: center;
    color: white;
}


.recommendedApps {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchbar {
    width: 80%;
    border-radius: 25px;
    border: 0;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.app {
    height: 100%;
    min-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.app svg {
    height: 100%;
    width: 100%;
}

.header {
    color: white;
}


.app:hover {
    background-color: gray;
    cursor: pointer;
}

.sideways {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.sideways svg {
    width: 35%;
    height: 50%;
}

.searchResults {
    width: 35%;
    height: 100%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.searchResultsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.explanationColumn {
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 3%;
    justify-content: flex-start;
    align-items: center;
    color: white;
}

.appIcon {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 55px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
