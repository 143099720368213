.aboutMe {
    width: 100%;
    min-height: 330vh;
    display: flex;
    flex-direction: column;
    color: #f0f0f0;
    background-color: #1A1A1A;
    background-image: 
        radial-gradient(circle at 25px 25px, rgba(255, 255, 255, 0.2) 2%, transparent 0%),
        radial-gradient(circle at 75px 75px, rgba(255, 255, 255, 0.2) 2%, transparent 0%);
    background-size: 100px 100px;
    font-family: 'Roboto', sans-serif;
}

.section {
    padding: 4rem 10%;
    max-width: 1200px;
    margin: 0 auto;
}

.aboutMeHeader {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 2rem;
    text-align: center;
    background: linear-gradient(45deg, #3498db, #2ecc71);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mission-statement {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 2rem;
    margin-top: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mission-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.mission-icon {
    font-size: 3rem;
    color: #3498db;
}

.mission-text {
    margin-bottom: 1rem;
    line-height: 1.6;
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: 1fr 1fr;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
}

.mission-text ul {
    list-style-type: none;
    padding-left: 0;
}

.mission-text ul li {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
}

.mission-text ul li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #3498db;
}

@media (max-width: 768px) {
    .section {
        padding: 2rem 5%;
    }

    .mission-content {
        flex-direction: column;
    }
}

.sectionOne {
    min-height: 1460px;
}

.centeredColumn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    max-width: 40%;
    min-width: 460px;
    padding-left: 5%;
    padding-right: 5%;
}

.rightColumn {
    padding-top: 50px;
}

.sectionHeightNormal {
    min-height: 40vh;
    padding-top: 5vh;
}

.myPicture {
    width: 50%;
    height: 50%;
    mix-blend-mode: overlay;
}

.flex {
    display: flex;
}

.centeredFlex {
    justify-content: center;
    align-items: center;
}

.pole {
    position: absolute;
    height: 15px;
    width: 20%;
    background: gray;
    bottom: 0;
    left: 0;
}

/* Hexagon CSS --------------------------------------------------------------------------------------------------------------------------------------------------- */
.hex {
    float: left;
    margin-left: 3px;
    margin-bottom: -47px;
    transition: all .2s;
    outline: 1ps solid white;
}

.hex .bottom svg {
    color: white;
}

.hex:hover {
    transform: translate(-10px, -10px);
    transition: all .2s;
}

.hex .top .shadow {
    width: 0%;
    border-bottom: 50px solid black;
    border-left: 75px solid transparent;
    border-right: 75px solid transparent;
    height: 0%;
    left: 18px;
    top: 3px;
    z-index: -1;
    position: absolute;
    filter: blur(3px);
}

.hex .top {
    width: 0;
    border-bottom: 50px solid rgb(45, 43, 59);
    border-left: 75px solid transparent;
    border-right: 75px solid transparent;
}

.hex .middle .shadow {
    width: 10%;
    height: 50%;
    z-index: -1;
    position: absolute;
    left: 101%;
    background: black;
    filter: blur(3px);
}

.hex .middle {
align-items: center;
    background: #2d2b3b;
    display: flex;
    height: 90px;
    justify-content: center;
    width: 150px;
}

.hex .bottom .shadow {
    width: 0%;
    border-top: 50px solid black;
    border-left: 75px solid transparent;
    border-right: 75px solid transparent;
    height: 0%;
    left: 18px;
    top: 140px;
    z-index: -1;
    position: absolute;
    filter: blur(3px);
}

.hex .bottom {
    width: 0;
    border-top: 50px solid rgb(45, 43, 59);
    border-left: 75px solid transparent;
    border-right: 75px solid transparent;
}

.hex-row {
    clear: left;
}

.hex-row .even {
    margin-left: 0px;
}

.hexagon {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 459px;
}

/* End Hexagon CSS -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* Add these styles to your existing CSS */

.aboutMe-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: space-around;
    padding: 1rem;
    z-index: 1000;
}

.aboutMe-nav a {
    color: #333;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.aboutMe-nav a:hover {
    color: #007bff;
}

.hex {
    position: relative;
}

.hex-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 0.5rem;
    border-radius: 4px;
}

.brickWall {
    padding-top: 60px;
}

.journey-section {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
}

.section-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
}

.journey-description {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
}

.journey-timeline {
    position: relative;
    padding: 2rem 0;
}

.timeline-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.timeline-item {
    position: relative;
    padding-left: 2rem;
    padding-bottom: 2rem;
    border-left: 2px solid #007bff;
}

.timeline-item:last-child {
    padding-bottom: 0;
}

.timeline-item::before {
    content: '';
    position: absolute;
    left: -0.5rem;
    top: 0;
    width: 1rem;
    height: 1rem;
    background: #007bff;
    border-radius: 50%;
}

.timeline-item h3 {
    font-size: 1.2rem;
    color: #007bff;
    margin-bottom: 0.5rem;
}

.timeline-item p {
    font-size: 1rem;
    line-height: 1.4;
}

.section-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    position: relative;
}

.section-title::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: #007bff;
    margin: 1rem auto 0;
}

ul {
    list-style-type: none;
    padding-left: 20px;
    margin-left: 0;
  }
  
  li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
  }
  
  li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #007bff; /* You can change this to match your color scheme */
  }

@media (max-width: 768px) {
    .mission-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .mission-icon {
        margin-bottom: 1rem;
    }

    .mission-text ul li {
        padding-left: 0;
    }

    .mission-text ul li::before {
        position: static;
        margin-right: 0.5rem;
    }
}

.knownLanguages {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
