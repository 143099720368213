.window {
    display: flex;
    height: 70vh;
    width: 70vw;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: absolute;
}

.hidden {
    width: 0!important;
    height: 0!important;
    overflow: hidden;
}

.fullscreen {
    transform: none!important;
    width: 100%!important;
    min-height: 100%!important;
    max-height: 100%!important;
    z-index: 9999999;
    display: flex;
    flex-direction: column;
}

.info { 
    width: 100%;
    height: 3.5vh;
    background-color: rgb(39,39,39);
    display: flex;
    justify-content: space-between;
}

.infoName {
    color: white!important;
    padding-left: 0;
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.infoName svg {
    color: white!important;
    width: 7%!important;
    height: 90%!important
}

.childContainer {
    background-color: azure;
    display: flex;
    flex-grow: 1;
    max-height: 100%;
    overflow: auto;

}
.actions {
    display: flex;
    width: 7vw;
    justify-content: space-around;
    color: white;
}

.actions div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.actions div:hover {
    background-color: gray;
    cursor: pointer;
}

.moveable-direction.moveable-nw,
.moveable-direction.moveable-ne,
.moveable-direction.moveable-sw,
.moveable-direction.moveable-se,
.moveable-control.moveable-origin {
    opacity: 0!important;
}