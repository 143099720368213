textarea {
    display: block;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.Notes {
    width: 100vw;
    height: 100%;
    background-color: white;
}

.NotesInput {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 255, 0);
    border: none;
    padding: .5%;
    margin: none;
    outline: none;
    resize: none
}